<template lang="pug">
  .dropdown-nav__wrapper
    transition(name="fadex")
      .dropdown-nav__overlay(v-if="showDropdown")
    .dropdown-nav__inner(v-click-outside="closeDropdown")
      .dropdown-nav__topbar(@click="toggleDropdown")
        .dropdown-nav__title {{ title | translate }}:
        .dropdown-nav__selected {{ activeElement.title | translate }}
      .dropdown-nav__toggler
      transition(name="fade-down")
        ul.dropdown-nav.portlet(v-if="showDropdown")
          li.space-between.dropdown-nav__item(v-for="item in options" :key="item.id" )
            router-link.dropdown-nav__item-link(
              :class="{ active: isActive(item) }"
              :to="item.path") {{ item.title | translate }}
        slot
</template>

<script>
export default {
  name: 'UiDropdownNav',

  props: {
    title: String,
    options: Array
  },

  data: () => ({
    showDropdown: false
  }),

  methods: {
    isActive (item) {
      return this.routePath.includes(item.path)
    },

    toggleDropdown () {
      this.showDropdown = !this.showDropdown
    },

    closeDropdown () {
      if (this.showDropdown) {
        this.showDropdown = false
      }
    }
  },

  computed: {
    activeElement () {
      return this.options.find(item => this.routePath.includes(item.path)) || {}
    },

    routePath () {
      return this.$route.path
    }
  },

  watch: {
    routePath () {
      this.closeDropdown()
    }
  }
}

</script>

<style lang="scss" scoped>
  .dropdown-nav__wrapper {
    .dropdown-nav__overlay {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
      background: rgba(0, 0, 40, 0.1);
    }

    .dropdown-nav__inner {
      position: relative;
      margin-bottom: 10px;

      &::after {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        content: "\f1ac";
        font-size: 1.25rem;
        font-family: Flaticon2;
        color: #a2a5b9;
        margin-left: 0.5rem;
        margin-right: 0.5rem
      }

      .dropdown-nav__topbar {
        cursor: pointer;
        display: flex;
        flex-direction: column;

        .dropdown-nav__title {
          font-size: 14px;
        }

        .dropdown-nav__selected {
          color: #333;
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 10px;
        }
      }

      .dropdown-nav {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0;
        padding: 15px 0;
        z-index: 1;
        display: flex;
        flex-direction: column;
        list-style: none;
        margin: 0 0 25px 0;

        .space-between {
          padding-right: 3%;
          align-items: center;
          display: flex;
          justify-content: space-between
        }

        .dropdown-nav__item {

          &:last-child {
            margin-bottom: 0;
          }

          .dropdown-nav__item-link {
            position: relative;
            display: block;
            color: $color-inactive-text;
            font-size: 16px;
            font-weight: 500;
            padding: 0.5rem 1.25rem;

            &.active {
              color: $color-primary;
              font-weight: 600;

              &::after {
                content: "";
                width: 3px;
                height: 12px;
                border-radius: 6px;
                margin: 4px auto 0;
                background-color: #14b38a;
                position: absolute;
                left: 0px;
                bottom: calc(50% - 6px);
              }
            }
          }
        }
      }
    }
  }
</style>
