<template lang="pug">
  .roles-wrapper
    v-scrollable
      .desktop-nav
        v-card(transparent)
          v-card-head
            v-card-head-label
              h2.title-2.mb-3 {{ 'pages.administration.roles' | translate }}
              p {{ 'infotext.roles_destination_description' | translate }}

          v-card-content(v-if="!loaded")
            .role-skeleton(
              v-for="i in 5"
              :key="i")

          template(v-else)
            v-card-content
              v-tab-item(
                v-for="item in specialRoles"
                vertical
                :key="item.id"
                :active="isActive(item)"
                :path="getRolePath(item)")
                | {{ item.name }}
              v-divider
              v-tab-item(
                v-for="item in customRoles"
                vertical
                :key="item.id"
                :active="isActive(item)"
                :path="getRolePath(item)")
                | {{ item.name }}

              v-tab-item(
                v-if="addNewItem.show"
                vertical)
                v-editable(
                  init-state
                  :disabled="addNewItem.disabled"
                  @close="onCreateRole")
                  .new-role-item {{ addNewItem.name }}

            v-card-actions(left)
              v-btn(
                text
                paddingless
                @click="addNewRole")
                i.fa.fa-plus
                span {{ 'pages.administration.create_new_role' | translate }}

    .mobile-nav
      ui-dropdown-nav.mb-4(
        :title="'ui.labels.role' | translate"
        :options="roleOptions")

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import UiDropdownNav from '@/components/ui/DropdownNav'

export default {
  name: 'RolesList',

  components: {
    UiDropdownNav
  },

  async mounted () {
    await this.init()
    this.loaded = true
  },

  data: () => ({
    loaded: false,
    roleId: null,
    addNewItem: {
      name: '',
      show: false,
      disabled: false
    }
  }),

  methods: {
    ...mapActions('app', [
      'showConfirmDialog'
    ]),

    ...mapActions('roles', [
      'loadRoles',
      'createRole'
    ]),

    async init () {
      if (!this.roles.length) {
        await this.loadRoles()
      }
      if (this.roles.length) {
        const roleId = +this.$route.params.role_id
        this.roleId = this.roles.some(item => item.id === roleId)
          ? roleId
          : this.roles[0].id
        const path = `/administration/permissions/${this.roleId}`
        if (this.$route.path !== path) {
          this.$router.push(path)
        }
      }
    },

    addNewRole () {
      this.addNewItem.name = `New role ${this.roles.length}`
      this.addNewItem.show = true
    },

    async onCreateRole (value) {
      this.addNewItem.name = value
      this.addNewItem.disabled = true
      const payload = {
        name: value
      }
      await this.createRole(payload)
      this.addNewItem.show = false
      this.addNewItem.disabled = false
    },

    getRolePath (item) {
      return `/administration/permissions/${item.id}`
    },

    isActive (item) {
      return +this.$route.params.role_id === item.id
    }
  },

  computed: {
    ...mapGetters('roles', [
      'roles'
    ]),

    specialRoles () {
      return this.roles
        .filter(item => item.isDefault ||
          item.isSuperAdmin || item.isSupervisor)
    },

    customRoles () {
      return this.roles
        .filter(item => !item.isDefault &&
          !item.isSuperAdmin && !item.isSupervisor)
    },

    routePath () {
      return this.$route.path
    },

    hasRoleIdInRoute () {
      return this.$route.params.role_id !== undefined
    },

    roleOptions () {
      return this.roles.map(item => {
        item.title = item.name
        item.path = `/administration/permissions/${item.id}`
        return item
      })
    }
  },

  watch: {
    routePath (value) {
      if (value.includes('/administration/permissions') &&
        !this.hasRoleIdInRoute && this.roleId !== null) {
        this.$router.push(`/administration/permissions/${this.roleId}`)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/sass/abstracts/_mixins.scss';

  .role-skeleton {
    margin: 1rem 0;
    height: 18px;
    border-radius: 5px;
    @include skeleton;
  }

  .new-role-item {
    padding: 2px 0;
  }
</style>
